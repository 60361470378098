import { createRouter, createWebHashHistory } from "vue-router";
import ClientMonitor from 'skywalking-client-js';
import config from '../../package.json'
// import Layout from "@/layout/layout.vue";
const routes = [
  {
    // path: "/",
    path: window.__MICRO_APP_BASE_ROUTE__ || '/',
    // component: Layout,
    redirect: "/auth",
    children: [
      {
        name: "auth",
        path: "auth",
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/auth"),
        meta: {
          title: "首页1",
          // keepAlive: true,
        },
      },
      {
        name: "index",
        path: "index",
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/index"),
        meta: {
          title: "首页",
          keepAlive: true,
        },
      },
      {
        name: "teacherList",
        path: 'teacherList/index',
        component: () =>
          import(/* webpackChunkName: "teacherList" */ "@/views/teacherList/index.vue"),
        meta: {
          title: "教师端首页",
          keepAlive: true,
        },
      },
      {
        name: "feedBackList",
        path: "feedBackList/index",
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/feedBackList/index"),
        meta: {
          title: "反馈列表",
          keepAlive: true,
        },
      },
      {
        name: "submitto",
        path: "submitto/index",
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/submitto/index"),
        meta: {
          title: "提交反馈",
          // keepAlive: true,
        },
      },
      {
        name: "feedBssdetails",
        path: "feedBssdetails/index",
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/feedBssdetails/index"),
        meta: {
          title: "反馈详情",
          // keepAlive: true,
        },
      },
      // consultation
      {
        name: "consultation",
        path: "consultation/index",
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/consultation/index"),
        meta: {
          title: "咨询页",
          // keepAlive: true,
        },
      },
    ],
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

// router.beforeEach((to, from, next) => {
//   const title = to.meta && to.meta.title;
//   if (title) {
//     document.title = title;
//   }
//   next();
// });
function startsWithCirclePage(str) {
  return /^\/circle-page/.test(str);
}

router.beforeEach((to, from, next) => {

  if (window.__MICRO_APP_BASE_ROUTE__) {
    const toPath = window.__MICRO_APP_BASE_ROUTE__ + to.fullPath;
    if (startsWithCirclePage(to.fullPath)) {
      next();
    } else {
      next({ path: toPath, query: { ...to.query }, params: { ...to.params } });
    }
  }

  next();
  if (window.microApp) {
    let dispatchData = { routePath: to.path, type: "routePath" };

    window.microApp?.dispatch(dispatchData);
  }
});
console.log(config)
router.afterEach((to, from) => {
  ClientMonitor.setPerformance({
    collector: 'https://skyapm-js.boran-tech.com',
    service: process.env.VUE_APP_WEBNAME,
    serviceVersion: config.version,//process.env.VUE_APP_VERSION,
    pagePath: location.href,
    useFmp: true
  });
});
export { router };
